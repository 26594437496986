import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { useEffect, useState } from "react";
import ListProduct from "../../../components/ListProduct";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { CONTACT_URL } from "../../../routes/routerUrl";
import TextEditor from "../../../components/TextEditor";
import Loading from "../../../components/Loading";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 468 },
		items: 4,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 468, min: 0 },
		items: 4,
		slidesToSlide: 1,
	},
};

const Product = () => {
	let { slug } = useParams();
	const [showProductImage, setShowProductImage] = useState("");
	const [product, setProduct] = useState({
		proNameVi: "",
		imgPaths: [],
	});
	const [isLoading, setIsLoading] = useState(false);
	const [relatedProducts, setRelatedProducts] = useState([]);

	useEffect(() => {
		getProduct(slug);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getProduct = async (slug) => {
		setIsLoading(true);
		await axios
			.get(`/product/list`, {
				params: {
					proSlug: slug,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					document.title = res.data.product.proNameVi;

					const newProduct = res.data.product;
					newProduct.imgPaths = res.data.product.imgPaths.split(",");
					setProduct(newProduct);
					setShowProductImage(newProduct.imgPaths[0]);

					getAllProducts(newProduct.categoryId, newProduct.id);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllProducts = async (cateId, productId) => {
		await axios
			.get(`/product/list`, {
				params: {
					cateId: cateId,
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setRelatedProducts(
						res.data.products
							.filter((product) => product.id !== productId)
							.map((product) => {
								return {
									...product,
									imgPaths: product.imgPaths ? product.imgPaths.split(",") : [],
								};
							}),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			<div className="title-layout">Chi tiết sản phẩm</div>
			<Container className="container-wrapper">
				<Breadcrumb
					style={{
						backgroundColor: "#F5F5F5",
					}}
					className="p-3 pb-1"
				>
					<Breadcrumb.Item active>Trang chủ</Breadcrumb.Item>
					<Breadcrumb.Item active>Loại sản phẩm</Breadcrumb.Item>
					<Breadcrumb.Item active>{product.proNameVi}</Breadcrumb.Item>
				</Breadcrumb>

				{isLoading ? (
					<Loading />
				) : (
					<>
						<Row className="pt-5">
							<Col xs="12" md="5">
								<div
									style={{
										width: "100%",
										aspectRatio: "900/600",
										backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${showProductImage})`,
										backgroundRepeat: "no-repeat",
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}
									className="mb-4"
								/>
								<Carousel responsive={responsive} autoPlay infinite={true} removeArrowOnDeviceType={["desktop", "mobile"]}>
									{product.imgPaths?.map((image, index) => {
										return (
											<div key={index} className="me-3">
												<div
													style={{
														width: "100%",
														aspectRatio: "900/600",
														backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${image})`,
														backgroundRepeat: "no-repeat",
														backgroundSize: "cover",
														backgroundPosition: "center",
														cursor: "pointer",
													}}
													onClick={() => setShowProductImage(image)}
												/>
											</div>
										);
									})}
								</Carousel>
							</Col>
							<Col xs="12" md="7" className="text-start">
								<h2>{product.proNameVi}</h2>

								<p className="my-3">{product.proDescVi}</p>

								{/* <div className="d-flex align-items-center mb-3">
							<div className="text-muted fw-bold me-2">
								Số lượng:
							</div>
						</div>
						<div className="d-flex align-items-center mb-3">
							<div className="text-muted fw-bold me-2">
								Màu sắc:
							</div>
							<Button
								size="sm"
								style={{
									borderRadius: 0,
								}}
								className="me-2 primary-button"
							>
								Màu 1
							</Button>

							<Button
								size="sm"
								style={{
									borderRadius: 0,
								}}
								className="me-2 primary-button"
							>
								Màu 2
							</Button>
						</div>
						<div className="d-flex align-items-center mb-3">
							<div className="text-muted fw-bold me-2">
								Bao bì:
							</div>
							<Button
								size="sm"
								style={{
									borderRadius: 0,
								}}
								className="me-2 primary-button"
							>
								Màu 1
							</Button>

							<Button
								size="sm"
								style={{
									borderRadius: 0,
								}}
								className="me-2 primary-button"
							>
								Màu 2
							</Button>
						</div> */}

								<div className="d-flex mt-4">
									<Button style={{ borderRadius: 0 }} as={Link} to={`/${CONTACT_URL}`} size="lg" className="fw-bold px-5 primary-button">
										Liên hệ tư vấn
									</Button>

									{/* <Button
								size="md"
								style={{
									borderRadius: 0,
								}}
								className="primary-button"
							>
								<Icon icon="heart" />
							</Button> */}
								</div>
							</Col>
						</Row>

						<div className="my-5">
							{product.proContentVi ? (
								<TextEditor
									disable
									enableToolbar={false}
									hideToolbar={true}
									setContents={product.proContentVi}
									onChange={() => {}}
									setOptions={{
										resizingBar: false,
										showPathLabel: false,
									}}
								/>
							) : (
								""
							)}
						</div>

						<ListProduct title="Sản phẩm liên quan" showRow={4} products={relatedProducts} />
					</>
				)}
			</Container>
		</>
	);
};

export default Product;
