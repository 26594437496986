import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../assets/image/Logo.png";
import Icon from "../../components/Icon";
import { Link } from "react-router-dom";

import { CONTACT, TOPIC, CATEGORY } from "../../routes/routerUrl";
import { useState } from "react";

function UserNavBar({ setShowSideBar, showSideBar, shop, topics, categories }) {
	const [showProductDropdown, setShowProductDropdown] = useState(false);
	const [showPostDropdown, setShowPostDropdown] = useState(false);

	const showProDropdown = (e) => {
		setShowProductDropdown(!showProductDropdown);
	};
	const hideProDropdown = (e) => {
		setShowProductDropdown(false);
	};
	const showPosDropdown = (e) => {
		setShowPostDropdown(!showProductDropdown);
	};
	const hidePosDropdown = (e) => {
		setShowPostDropdown(false);
	};

	return (
		<>
			<div
				style={{ backgroundColor: "#f5f5f5" }}
				className="header-show py-2 w-100"
			>
				<Container className="container-wrapper">
					<div className="d-flex justify-content-between">
						<div className="d-flex">
							<div>
								<Icon icon="envelope" /> {shop.shopEmail}
							</div>
							<div className="mx-2">|</div>
							<div>
								<Icon icon="phone" /> {shop.shopPhone}
							</div>
						</div>

						<div className="d-flex">
							<div className="d-flex">
								<Link
									to={shop.shopDescVi}
									target="_blank"
									style={{
										fontSize: "1rem",
										color: "black",
										textDecoration: "none",
									}}
									className="fw-bold me-2"
								>
									Facebook
								</Link>

								<Link
									to={`https://zalo.me/${shop.shopDescEn}`}
									target="_blank"
									style={{
										fontSize: "1rem",
										color: "black",
										textDecoration: "none",
									}}
									className="fw-bold"
								>
									Zalo
								</Link>
							</div>
						</div>
					</div>
				</Container>
			</div>

			<Navbar
				sticky="top"
				expand="lg"
				className="w-100 px-0"
				style={{
					zIndex: "1050",
					boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
					backgroundColor: "white",
				}}
			>
				<Container className="container-wrapper">
					<Link to="/">
						<img
							src={Logo}
							alt="HaoNguyen"
							style={{ width: "3rem", height: "3rem" }}
						/>
					</Link>

					<div className="d-flex justify-content-center align-items-center">
						<Navbar.Collapse id="navbarScroll">
							<Nav
								className="me-auto my-2 my-lg-0 align-items-center"
								navbarScroll
							>
								<Link to="/" className="navbar-text">
									Trang chủ
								</Link>

								<div className="mx-3" />

								<NavDropdown
									title={
										<span className="navbar-text">
											Sản phẩm
										</span>
									}
									id="navbarScrollingDropdown"
									show={showProductDropdown}
									onMouseEnter={showProDropdown}
									onMouseLeave={hideProDropdown}
								>
									{categories?.map((category) => {
										return (
											<NavDropdown.Item
												key={category.id}
												as={Link}
												to={`/${CATEGORY}${category.cateSlug}`}
												className="sub-navbar-text"
											>
												{category.cateNameVi}
											</NavDropdown.Item>
										);
									})}
								</NavDropdown>

								<div className="mx-3" />

								<NavDropdown
									title={
										<span className="navbar-text">
											Tin tức
										</span>
									}
									id="navbarScrollingDropdown"
									className="navbar-text"
									show={showPostDropdown}
									onMouseEnter={showPosDropdown}
									onMouseLeave={hidePosDropdown}
								>
									{topics?.map((topic) => {
										return (
											<NavDropdown.Item
												key={topic.id}
												as={Link}
												to={`/${TOPIC}${topic.cateSlug}`}
												className="sub-navbar-text"
											>
												{topic.cateNameVi}
											</NavDropdown.Item>
										);
									})}
								</NavDropdown>

								<div className="mx-3" />

								<Link
									to={`/${CONTACT}`}
									className="navbar-text"
								>
									Liên hệ
								</Link>
							</Nav>
						</Navbar.Collapse>
					</div>

					<div
						className="sidebar-section toggle-button"
						onClick={() => {
							setShowSideBar(!showSideBar);
						}}
					>
						<Icon icon="bars" />
					</div>

					<div className="header-show" />
				</Container>
			</Navbar>
		</>
	);
}

export default UserNavBar;
