import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../utils/yupGlobal";
import Input from "../../components/Input";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const settingFormItems = [
	{
		id: 1,
		placeholder: "Tên cửa hàng",
		name: "name",
	},
	{
		id: 2,
		placeholder: "Email",
		name: "email",
	},
	{
		id: 3,
		placeholder: "Số điện thoại",
		name: "phonenumber",
	},
	{
		id: 4,
		placeholder: "Địa chỉ",
		name: "address",
	},
	{
		id: 5,
		placeholder: "Facebook",
		name: "facebook",
	},
	{
		id: 6,
		placeholder: "Zalo",
		name: "zalo",
	},
	{
		id: 7,
		placeholder: "Giờ mở cửa",
		name: "openningHour",
	},
	// {
	// 	id: 10,
	// 	placeholder: "Zalo",
	// 	placeholderEn: "Zalo",
	// 	name: "zaloNumber",
	// },
	{
		id: 11,
		placeholder: "Script in Header",
		placeholderEn: "Script in Header",
		name: "headerScript",
	},
	{
		id: 12,
		placeholder: "Script in Footer",
		placeholderEn: "Script in Footer",
		name: "footerScript",
	},
];

const Setting = () => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		getSetting();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getSetting = async () => {
		await axios
			.get(`/shop/detail`)
			.then((res) => {
				if (res.data.errCode === 0) {
					reset({
						name: res.data.shop.shopNameVi,
						email: res.data.shop.shopEmail,
						phonenumber: res.data.shop.shopPhone,
						address: res.data.shop.shopAddressVi,
						openningHour: res.data.shop.shopAddressEn,
						facebook: res.data.shop.shopDescVi,
						zalo: res.data.shop.shopDescEn,
						headerScript: res.data.shop.headerScript,
						footerScript: res.data.shop.footerScript,
					});
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateSetting = async (data) => {
		await axios
			.put(`/shop/update`, {
				shopNameVi: data.name,
				shopEmail: data.email,
				shopPhone: data.phonenumber,
				shopAddressVi: data.address,
				shopAddressEn: data.openningHour,
				shopDescVi: data.facebook,
				shopDescEn: data.zalo,
				headerScript: data.headerScript,
				footerScript: data.footerScript,
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<Container fluid className="mt-3">
			<div
				style={{
					backgroundColor: "rgba(221,237,224,255)",
					fontSize: "1.5rem",
				}}
				className="text-center w-100 mb-3 py-3"
			>
				Cài đặt
			</div>
			<Card>
				<Card.Body>
					<Form
						onSubmit={handleSubmit((data) => {
							updateSetting(data);
						})}
					>
						{settingFormItems?.map((formItem) => {
							return (
								<Form.Group key={formItem.id} as={Row}>
									<Form.Label
										column
										sm="3"
										className="fw-bold"
									>
										{formItem.placeholder}
									</Form.Label>
									<Col sm="6">
										<Input
											placeholder={formItem.placeholder}
											name={formItem.name}
											register={register(formItem.name)}
											errors={errors[formItem.name]}
											className="mb-3"
											as={
												formItem.id === 11 ||
												formItem.id === 12
													? "textarea"
													: "input"
											}
											rows={3}
										/>
									</Col>
								</Form.Group>
							);
						})}

						<Button
							type="submit"
							size="lg"
							className="mlb-button my-3 w-100"
						>
							Cập nhật
						</Button>
					</Form>
				</Card.Body>
			</Card>
		</Container>
	);
};

const Schema = yup.object().shape({
	name: yup.string().required("Không được bỏ trống"),
	email: yup.string().required("Không được bỏ trống"),
	phonenumber: yup
		.string()
		.required("Không được bỏ trống")
		.phone("Sai định dạng số điện thoại"),
	zalo: yup.string().phone("Sai định dạng số điện thoại"),
});

export default Setting;
