import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<footer
			style={{ backgroundColor: "#147efb", color: "white" }}
			className="py-2 mt-auto"
		>
			<div className="container-fluid px-4">
				<div className="d-flex align-items-center justify-content-between small">
					<div>Copyright by Katec</div>
					<div>
						<Link style={{ color: "white" }} to="#!">
							Chính sách bảo mật
						</Link>
						<span>&nbsp;|&nbsp;</span>
						<Link style={{ color: "white" }} to="#!">
							Điều khoản
						</Link>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
