import axios from "axios";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import ListPost from "../../components/ListPost";
import { useState } from "react";
import { useEffect } from "react";
import ListProduct from "../../components/ListProduct";
import { useParams } from "react-router-dom";

const Search = () => {
	let { slug } = useParams();

	const [products, setProducts] = useState([]);
	const [posts, setPosts] = useState([]);
	const [isLoading, setIsLoading] = useState([]);

	useEffect(() => {
		document.title = "Tìm kiếm";
		getAllProducts(slug);
		getAllPosts(slug);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getAllProducts = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/product/list`, {
				params: {
					keyword: keyword || "",
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setProducts(
						res.data.products.map((product) => {
							return {
								...product,
								imgPaths: product.imgPaths
									? product.imgPaths.split(",")
									: [],
							};
						}),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllPosts = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/post/list`, {
				params: {
					keyword: keyword || "",
					limit: 6,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setPosts(res.data.posts);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Container className="container-wrapper">
			<ListProduct
				title="Sản phẩm"
				showRow={4}
				products={products}
				isLoading={isLoading}
			/>

			<ListPost
				title="Tin tức"
				showRow={3}
				posts={posts}
				isLoading={isLoading}
			/>
		</Container>
	);
};

export default Search;
