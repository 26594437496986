import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

import yup from "../../../utils/yupGlobal";

import Input from "../../../components/Input";

const registerFormItems = [
	{
		id: 1,
		placeholder: "Tên đăng nhập",
		placeholderEn: "Username",
		name: "username",
	},
	{
		id: 2,
		placeholder: "Mật khẩu",
		placeholderEn: "Password",
		name: "password",
	},
	{
		id: 3,
		placeholder: "Nhập lại mật khẩu",
		placeholderEn: "Confirm Password",
		name: "confirmPassword",
	},
	{
		id: 4,
		placeholder: "Họ và tên",
		placeholderEn: "Fullname",
		name: "fullname",
	},
	{
		id: 5,
		placeholder: "Email",
		placeholderEn: "Email",
		name: "email",
	},
	{
		id: 6,
		placeholder: "Số điện thoại",
		placeholderEn: "Phone number",
		name: "phonenumber",
	},
	{
		id: 7,
		placeholder: "Địa chỉ",
		placeholderEn: "Address",
		name: "address",
	},
];

const MemberAddModal = ({ handleCloseModal, showModal }) => {
	const [isSendForm, setIsSendForm] = useState(false);
	const [message, setmessage] = useState({
		username: undefined,
		password: undefined,
		email: undefined,
		phonenumber: undefined,
		address: undefined,
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	// Api
	const registerUser = (data) => {
		setIsSendForm(true);
		axios
			.post(`/admin/create`, {
				username: data.username,
				password: data.password,
				fullname: data.fullname,
				email: data.email,
				phonenumber: data.phonenumber,
				address: data.address,
			})
			.then((res) => {
				setIsSendForm(false);
				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});

					handleCloseModal();
				} else {
					setmessage(res.data.message);
				}
			});
	};
	// Api

	return (
		<Modal
			size="lg"
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>Thêm mới thành viên</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="add-member-hook-form"
					onSubmit={handleSubmit((data) => {
						registerUser(data);
					})}
				>
					{registerFormItems?.map((item) => {
						return (
							<React.Fragment key={item.id}>
								<Input
									type={
										item.id === 2 || item.id === 3
											? "password"
											: "text"
									}
									placeholder={item.placeholder}
									name={item.name}
									register={register(item.name)}
									errors={errors[item.name]}
									className="mb-3"
								/>

								{message[item.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{message[item.name]}
									</p>
								)}
							</React.Fragment>
						);
					})}
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					Đóng
				</Button>
				<Button
					type="submit"
					form="add-member-hook-form"
					variant="primary"
					disabled={isSendForm}
				>
					Lưu
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const Schema = yup.object().shape({
	password: yup
		.string()
		.required("Không được bỏ trống")
		.test(
			"len",
			"Mật khẩu nên dài từ 6-32 kí tự",
			(val) => val.length >= 6 && val.length <= 32,
		),
	username: yup
		.string()
		.required("Không được bỏ trống")
		.test(
			"len",
			"Tên đăng nhập nên dài từ 6-32 kí tự",
			(val) => val.length >= 6 && val.length <= 32,
		),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "Mật khẩu không trùng khớp"),
	fullname: yup.string().required("Không được bỏ trống"),
	phonenumber: yup
		.string()
		.required("Không được bỏ trống")
		.phone("Số điện thoại không đúng định dạng"),
	email: yup
		.string()
		.email("Email không đúng định dạng")
		.required("Không được bỏ trống"),
	address: yup.string().required("Không được bỏ trống"),
});

export default MemberAddModal;
