import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../assets/image/Logo.png";
import { Link } from "react-router-dom";
import { CATEGORY, TOPIC } from "../../routes/routerUrl";

const footerItem = [
	{
		id: 1,
		title: "Sơ đồ trang",
		subTitle: [
			{
				id: 1,
				path: "/",
				title: "Trang chủ",
			},
			{
				id: 2,
				path: `/${CATEGORY}tat-ca`,
				title: "Sản phẩm",
			},
			{
				id: 3,
				path: `/${TOPIC}tat-ca`,
				title: "Tin tức",
			},
			{
				id: 4,
				path: "/lien-he",
				title: "Liên hệ",
			},
		],
	},
	// {
	// 	id: 2,
	// 	title: "---",
	// 	subTitle: [
	// 		{
	// 			id: 1,
	// 			title: "Về chúng tôi",
	// 		},
	// 		{
	// 			id: 2,
	// 			title: "Hướng Dẫn Mua Hàng",
	// 		},
	// 		{
	// 			id: 3,
	// 			title: "Chính Sách Bảo Hành",
	// 		},
	// 		{
	// 			id: 4,
	// 			title: "Chính Sách Đổi Hàng",
	// 		},
	// 		{
	// 			id: 5,
	// 			title: "Chính Sách Vận Chuyển",
	// 		},
	// 		{
	// 			id: 6,
	// 			title: "Câu hỏi thường gặp",
	// 		},
	// 	],
	// },
];

const Footer = ({ shop }) => {
	return (
		<footer className="text-start bg-light">
			<section className="py-5">
				<Container className="container-wrapper text-start">
					<Row>
						<Col xs="12" md="5" className="mx-auto">
							<img
								src={Logo}
								alt="Viettel"
								style={{ width: "3rem", height: "3rem" }}
								className="mb-4"
							/>

							<div
								className="fw-normal"
								style={{ fontSize: "1rem" }}
							>
								<div className="fw-bold d-inline">
									Địa chỉ:{" "}
								</div>
								{shop.shopAddressVi}
							</div>

							<div
								className="fw-normal my-3"
								style={{ fontSize: "1rem" }}
							>
								<div className="fw-bold d-inline">
									Số điện thoại:{" "}
								</div>
								{shop.shopPhone}
							</div>

							<div
								className="fw-normal"
								style={{ fontSize: "1rem" }}
							>
								<div className="fw-bold d-inline">Email: </div>
								{shop.shopEmail}
							</div>
						</Col>

						{footerItem?.map((footItem, index) => {
							return (
								<Col
									key={footItem.id}
									className="mx-auto pt-3 pt-md-0"
								>
									<h6
										className="fw-bold"
										style={
											index === 1 ? { opacity: "0" } : {}
										}
									>
										{footItem.title}
									</h6>
									{footItem.subTitle?.map((subItem) => {
										return (
											<Link
												key={subItem.id}
												className="footer-text"
												to={subItem.path}
											>
												<p>{subItem.title}</p>
											</Link>
										);
									})}
								</Col>
							);
						})}

						<Col xs="12" md="4" className="mx-auto">
							<h6 className="fw-bold">Google Maps</h6>
							<iframe
								title="thach cao hao nguyen"
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.9489784787443!2d105.77662864174249!3d10.021069247210677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a088281ad923db%3A0x32409f22df7de016!2zMzEgUXVhbmcgVHJ1bmcsIEjGsG5nIFBow7osIEPDoWkgUsSDbmcsIEPhuqduIFRoxqEsIFZpZXRuYW0!5e0!3m2!1sen!2s!4v1695434989741!5m2!1sen!2s"
								width="100%"
								height="200"
								allowFullScreen=""
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
							/>
						</Col>
					</Row>
				</Container>
			</section>

			<section
				className="py-3"
				style={{ borderTop: "1px solid #ebebeb" }}
			>
				<h6 className="text-center text-muted">Developed by Katec</h6>
			</section>
		</footer>
	);
};

export default Footer;
