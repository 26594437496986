import ListPost from "../../../components/ListPost";
import PostWrapper from "./PostWrapper";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import DateFormat from "../../../components/DateFormat";
import TextEditor from "../../../components/TextEditor";
import Loading from "../../../components/Loading";

const Post = () => {
	let { slug } = useParams();

	const [post, setPost] = useState({
		postTitleVi: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [realatedPosts, setRelatedPosts] = useState([]);

	useEffect(() => {
		getPost(slug);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getPost = async (slug) => {
		setIsLoading(true);

		await axios
			.get(`/post/list`, {
				params: {
					postSlug: slug,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setPost(res.data.post);

					getAllPosts(res.data.post.categoryId, res.data.post.id);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllPosts = async (cateId, postId) => {
		await axios
			.get(`/post/list`, {
				params: {
					cateId: cateId,
					limit: 6,
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setRelatedPosts(res.data.posts.filter((post) => post.id !== postId));
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<PostWrapper
			title={post.postTitleVi}
			breadcrumb={
				<>
					<Breadcrumb.Item active>Chủ đề</Breadcrumb.Item>
					<Breadcrumb.Item active>{post.postTitleVi}</Breadcrumb.Item>
				</>
			}
		>
			{isLoading ? (
				<Loading />
			) : (
				<>
					<div className="mb-5">
						<div className="d-flex align-items-center mb-3">
							<div style={{ color: "#727272", fontSize: "0.75rem" }} className="me-1">
								Ngày đăng:
							</div>
							<DateFormat date={post.updatedAt} />
						</div>

						{post.postContentVi ? (
							<TextEditor
								disable
								enableToolbar={false}
								hideToolbar={true}
								setContents={post.postContentVi}
								onChange={() => {}}
								setOptions={{
									resizingBar: false,
									showPathLabel: false,
								}}
							/>
						) : (
							""
						)}
					</div>

					<ListPost title="Tin tức liên quan" showRow={3} posts={realatedPosts} />
				</>
			)}
		</PostWrapper>
	);
};

export default Post;
