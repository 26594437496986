import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Button, Modal } from "react-bootstrap";

const BannerEditModal = ({ handleCloseModal, showModal, selectedBanner }) => {
	const [banner, setBanner] = useState("");
	const [info, setInfo] = useState({});

	const [isSendForm, setIsSendForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getAllBanner(selectedBanner);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Api
	const getAllBanner = async (bannerId) => {
		setIsLoading(true);
		await axios
			.get(`/image/show`, {
				params: {
					id: bannerId,
					isType: "iHome",
				},
			})
			.then(async (res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setInfo(res.data.image);

					const response = await fetch(
						`${process.env.REACT_APP_BACKEND_URL}${res.data.image.imgURL}`,
					);
					const blob = await response.blob();
					const file = new File([blob], "image.jpg", {
						type: blob.type,
					});
					setBanner(file);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateNewBanner = async (data) => {
		setIsSendForm(true);

		var bodyFormData = new FormData();
		bodyFormData.append("id", selectedBanner);
		bodyFormData.append("imageFile", banner);
		bodyFormData.append("locaId", info.locaId);

		await axios({
			method: "put",
			url: "image/update",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleUploadImage = (event) => {
		try {
			if (
				!event.target.files[0].type.match(/image.*/) ||
				event.target.files[0].size > 2097152
			) {
				toast("File ảnh phải nhỏ hơn bằng 2MB", {
					type: "error",
					autoClose: 1000,
				});

				return;
			}

			setBanner(event.target.files[0]);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>Cập nhật banner </Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{/* Image */}
				<div>
					{banner ? (
						<div className="d-flex justify-content-center">
							<div
								style={{
									aspectRatio: "900/600",
									backgroundImage: `url("${URL.createObjectURL(
										banner,
									)}")`,
									backgroundSize: "cover",
									width: "70%",
								}}
								className="image-default position-relative mb-3"
							/>
						</div>
					) : (
						""
					)}

					<input
						type="file"
						accept="image/x-png,image/gif,image/jpeg"
						onChange={(e) => handleUploadImage(e)}
						required
					/>
					<div>
						<div className="d-inline fw-bold">Note: </div>
						Chọn ảnh với tỉ lệ 900:600 để được chất lượng tốt nhất.
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					Đóng
				</Button>
				<Button
					variant="primary"
					onClick={() => updateNewBanner()}
					disabled={isSendForm || isLoading}
				>
					Cập nhật
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default BannerEditModal;
