import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
	CATEGORY,
	//  POST,
	TOPIC,
} from "../../../routes/routerUrl";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
// import DateFormat from "../../../components/DateFormat";

const PostWrapper = ({ title, breadcrumb, children }) => {
	const location = useLocation();

	// const [newPosts, setNewPosts] = useState([]);
	const [topics, setTopics] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		// getNewPosts();
		getAllTopics();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	// const getNewPosts = async () => {
	// 	await axios
	// 		.get(`/post/list`, {
	// 			params: {
	// 				limit: 3,
	// 			},
	// 		})
	// 		.then((res) => {
	// 			if (res.data.errCode === 0) {
	// 				setNewPosts(res.data.posts);
	// 			} else {
	// 				toast(res.data.message, {
	// 					type: "error",
	// 					autoClose: 1000,
	// 				});
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const getAllTopics = async () => {
		await axios
			.get(`/category/list`, {
				params: {
					isType: "cPost",
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setTopics(
						[
							{ id: 0, cateNameVi: "Tất cả", cateSlug: "tat-ca" },
						].concat(
							res.data.categories.filter(
								(category) => category.parentId !== null,
							),
						),
					);
					getAllCategories();
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllCategories = async () => {
		await axios
			.get(`/category/list`, {
				params: {
					isType: "cPro",
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setCategories(
						res.data.categories.filter(
							(category) => category.parentId !== null,
						),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<>
			<div className="title-layout">{title}</div>
			<Container className="container-wrapper">
				<Breadcrumb
					style={{
						backgroundColor: "#F5F5F5",
					}}
					className="p-3 pb-1"
				>
					<Breadcrumb.Item active>Trang chủ</Breadcrumb.Item>
					{breadcrumb}
				</Breadcrumb>
				<Row className="py-5">
					<Col xs="12" md="3">
						<div
							style={{
								position: "sticky",
								top: "5.5rem",
							}}
						>
							<div>
								<h6 className="fw-bold">Chủ đề</h6>
								<br />

								{topics?.map((topic) => {
									return (
										<Link
											key={topic.id}
											className={
												location.pathname.endsWith(
													`/${topic.cateSlug}`,
												)
													? "text-link text-link-hover"
													: "text-link"
											}
											to={`/${TOPIC}${topic.cateSlug}`}
										>
											<p>{topic.cateNameVi}</p>
										</Link>
									);
								})}
							</div>

							{/* <div className="my-5">
							<h6 className="fw-bold">Tin tức mới</h6>
							<br />

							{newPosts?.map((post) => {
								return (
									<div
										key={post.id}
										style={{ height: "100%" }}
										className="mt-3"
									>
										<Link
											to={`/${POST}${post.postSlug}`}
											style={{
												textDecoration: "none",
												color: "black",
											}}
										>
											<Row>
												<Col xs="4">
													<div
														style={{
															width: "100%",
															height: "100%",
															backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${post.imgURL})`,
															backgroundRepeat:
																"no-repeat",
															backgroundSize:
																"cover",
														}}
													/>
												</Col>
												<Col xs="8">
													<div>
														<div className="fw-bold text-overflow-2-line">
															{post.postTitleVi}
														</div>

														<DateFormat
															date={
																post.updatedAt
															}
														/>
													</div>
												</Col>
											</Row>
										</Link>
									</div>
								);
							})}
						</div> */}

							<div className="my-5" />

							<div>
								<h6 className="fw-bold">Sản phẩm</h6>
								<br />

								<Row>
									{categories.map((category) => {
										return (
											<Col xs={6} key={category.id}>
												<Link
													className="text-link"
													to={`/${CATEGORY}${category.cateSlug}`}
												>
													<div
														style={{
															backgroundColor:
																"#f5f5f5",
														}}
														className="text-center p-2 mb-2"
													>
														{category.cateNameVi}
													</div>
												</Link>
											</Col>
										);
									})}
								</Row>
							</div>
						</div>
					</Col>
					<Col xs="12" md="9">
						{children}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default PostWrapper;
