import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import { CATEGORY, TOPIC } from "../../routes/routerUrl";

export const SidebarData = [
	{
		id: 2,
		title: "Sản phẩm",
	},
	{
		id: 3,
		title: "Tin tức",
	},
];

const Sidebar = ({ setShowSideBar, showSideBar, shop, topics, categories }) => {
	return (
		<nav
			className={`${
				showSideBar ? "nav-menu active" : "nav-menu"
			} sidebar-section p-4`}
		>
			<div className="mb-4">
				{/* <Link
					to="/dang-nhap"
					className="sidebar-text"
					onClick={() => setShowSideBar(false)}
				>
					<div className="py-2 border-bottom">
						<Icon icon="user" /> Đăng nhập
					</div>
				</Link> */}

				<div className="d-flex justify-content-between py-2 border-bottom">
					<Link
						to={`/`}
						className="sidebar-text"
						onClick={() => setShowSideBar(false)}
					>
						Trang chủ
					</Link>
				</div>

				{SidebarData?.map((item) => {
					return (
						<React.Fragment key={item.id}>
							<div className="d-flex justify-content-between py-2 border-bottom">
								<button
									style={{
										backgroundColor: "var(--white)",
										border: "none",
									}}
									data-bs-toggle="collapse"
									href={`#${item.id}-sidebar`}
									className="sidebar-text p-0 m-0 w-100 d-flex justify-content-between align-items-center"
								>
									<div>{item.title}</div>
									<Icon icon="chevron-down" />
								</button>
							</div>

							<div
								className="collapse ps-3"
								id={`${item.id}-sidebar`}
							>
								{item.id === 2
									? categories.map((subItem) => {
											return (
												<div
													key={subItem.id}
													className="py-2 border-bottom"
												>
													<Link
														to={`/${CATEGORY}${subItem.cateSlug}`}
														className="sidebar-text"
													>
														{subItem.cateNameVi}
													</Link>
												</div>
											);
									  })
									: topics.map((subItem) => {
											return (
												<div
													key={subItem.id}
													className="py-2 border-bottom"
												>
													<Link
														to={`/${TOPIC}${subItem.cateSlug}`}
														className="sidebar-text"
														onClick={() =>
															setShowSideBar(
																false,
															)
														}
													>
														{subItem.cateNameVi}
													</Link>
												</div>
											);
									  })}
							</div>
						</React.Fragment>
					);
				})}

				<div className="d-flex justify-content-between py-2 border-bottom">
					<Link
						to={`/lien-he`}
						className="sidebar-text"
						onClick={() => setShowSideBar(false)}
					>
						Liên hệ
					</Link>
				</div>
			</div>

			<div className="d-flex mb-4">
				{/* <div className="sidebar-text me-3">Facebook</div> */}
				<Link
					to={`https://zalo.me/${shop.shopPhone}`}
					target="_blank"
					style={{
						fontSize: "1rem",
						color: "black",
						textDecoration: "none",
					}}
					className="fw-bold"
				>
					Zalo
				</Link>
			</div>

			<div>
				<div style={{ fontSize: "0.9rem" }}>
					<Icon icon="envelope" /> {shop.shopEmail}
				</div>
				<div style={{ fontSize: "0.9rem" }}>
					<Icon icon="phone" /> {shop.shopPhone}
				</div>
			</div>
		</nav>
	);
};

export default Sidebar;
