import {
	Button,
	Card,
	Col,
	Container,
	Form,
	InputGroup,
	Row,
} from "react-bootstrap";
import Input from "../../../components/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BackgroundImage from "../../../assets/image/6874264.jpg";
import { useState } from "react";
import Icon from "../../../components/Icon";

const Login = () => {
	const navigate = useNavigate();
	const [passwordShow, setPasswordShow] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		document.title = "Đăng nhập";

		if (localStorage.getItem("access_token")) {
			navigate("/admin");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const login = (data) => {
		axios
			.post(`/login`, {
				userName: data.username,
				password: data.password,
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					localStorage.setItem(
						"access_token",
						res.data.data.accessToken,
					);

					toast(res.data.message, {
						type: "success",
						autoClose: 2000,
					});

					window.location.href = "/admin";
				} else {
					toast("Tên đăng nhập hoặc mật khẩu không đúng", {
						type: "error",
						autoClose: 2000,
					});
				}
			});
	};

	return (
		<div
			style={{
				height: "100vh",
				backgroundImage: `url(${BackgroundImage})`,
				backgroundSize: "cover",
			}}
			className="image-default"
		>
			<Container className="h-100">
				<Row className="h-100 align-items-center justify-content-center">
					<Col xs="12" sm="8" md="6" xl="5">
						<Card className="py-3 px-3 px-md-4">
							<div
								style={{
									fontSize: "1.5rem",
								}}
								className="mb-3"
							>
								Đăng nhập
							</div>

							<Form
								onSubmit={handleSubmit((data) => {
									login(data);
								})}
							>
								<Input
									placeholder={"Tên đăng nhập"}
									name="username"
									register={register("username")}
									errors={errors.username}
									className="mb-3"
								/>

								<div className="mb-3">
									<InputGroup>
										<Form.Control
											placeholder={"Mật khẩu"}
											type={
												passwordShow
													? "text"
													: "password"
											}
											name="password"
											{...register("password")}
										/>
										<Button
											onClick={() => {
												setPasswordShow(!passwordShow);
											}}
										>
											<Icon
												icon={
													passwordShow
														? "eye-slash"
														: "eye"
												}
											/>
										</Button>
									</InputGroup>
									{errors.password && (
										<p
											style={{
												fontSize: 13,
												color: "red",
												marginTop: 6,
											}}
										>
											{errors.password.message}
										</p>
									)}
								</div>

								<div className="text-center">
									<Button type="submit" size="md">
										Đăng nhập
									</Button>
								</div>
							</Form>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const Schema = yup.object().shape({
	password: yup.string().required("Không được bỏ trống"),
	username: yup.string().required("Không được bỏ trống"),
});

export default Login;
