import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import UserLayout from "../layouts/user/MasterLayout";
import Home from "../pages/user/Home";

import Login from "../pages/user/Auth/Login";
import Search from "../pages/user/Search";
import Category from "../pages/user/Product/Category";
import Product from "../pages/user/Product/Product";
import Topic from "../pages/user/Post/Topic";
import Post from "../pages/user/Post/Post";
import Contact from "../pages/user/Contact";

import AdminLayout from "../layouts/admin/MasterLayout";
import Dashboard from "../pages/admin/Dashboard";

import CategoryAdmin from "../pages/admin/Category/Category";
import ProductAdmin from "../pages/admin/Product/Product";
import TopicAdmin from "../pages/admin/Topic/Topic";
import PostAdmin from "../pages/admin/Post/Post";
import Setting from "../pages/admin/Setting";
import MemberAdmin from "../pages/admin/Member/Member";
import BannerAdmin from "../pages/admin/Banner/Banner";

// URLs
import {
	CATEGORY_URL,
	PRODUCT_URL,
	TOPIC_URL,
	POST_URL,
	CONTACT_URL,
} from "./routerUrl";
import NotFound from "../pages/NotFound";

export default function router() {
	return (
		<>
			<BrowserRouter>
				<ToastContainer position="top-right" />

				<ScrollToTop />
				<Routes>
					<Route path={`/dang-nhap`} element={<Login />} />

					<Route path="/" element={<UserLayout />}>
						<Route index element={<Home />} />
						<Route path={CATEGORY_URL} element={<Category />} />
						<Route path={PRODUCT_URL} element={<Product />} />
						<Route path={"tim-kiem"} element={<Search />} />
						<Route path={"tim-kiem/:slug"} element={<Search />} />
						<Route path={TOPIC_URL} element={<Topic />} />
						<Route path={POST_URL} element={<Post />} />
						<Route path={CONTACT_URL} element={<Contact />} />

						<Route path="*" element={<NotFound path={"/"} />} />
					</Route>

					<Route path="/admin" element={<AdminLayout />}>
						<Route index element={<Dashboard />} />
						<Route path="dashboard" element={<Dashboard />} />

						<Route path="category" element={<CategoryAdmin />} />
						<Route path="product" element={<ProductAdmin />} />
						<Route path="topic" element={<TopicAdmin />} />
						<Route path="post" element={<PostAdmin />} />
						<Route path="member" element={<MemberAdmin />} />
						<Route path="setting" element={<Setting />} />
						<Route path="banner" element={<BannerAdmin />} />

						<Route
							path="*"
							element={<NotFound path={"/admin"} />}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	);
}
