import { Breadcrumb } from "react-bootstrap";
import ListPost from "../../../components/ListPost";
import PostWrapper from "./PostWrapper";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const itemsPerPage = 16;

const Topic = () => {
	let { slug } = useParams();
	const [posts, setPosts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(1);

	const [isLoading, setIsLoading] = useState([]);

	useEffect(() => {
		document.title = "Tin tức";
		getAllPosts(slug, 1);
	}, [slug]);

	useEffect(() => {
		getAllPosts(slug, currentPage);
	}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getAllPosts = async (slug, page) => {
		setIsLoading(true);
		await axios
			.get(`/post/list`, {
				params: {
					cateSlug: slug === "tat-ca" ? "" : slug,
					page: page,
					limit: itemsPerPage,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setTotalItems(res.data.itemCount);
					setPosts(res.data.posts);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<PostWrapper
			title="Tin tức"
			breadcrumb={<Breadcrumb.Item active>Tin tức</Breadcrumb.Item>}
		>
			<ListPost
				showRow={2}
				posts={posts}
				isLoading={isLoading}
				itemsCount={totalItems}
				itemsPerPage={itemsPerPage}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				isPagiantion={true}
			/>
		</PostWrapper>
	);
};

export default Topic;
