export const CATEGORY_URL = "danh-muc-san-pham/:slug";
export const CATEGORY = "danh-muc-san-pham/";

export const PRODUCT_URL = "san-pham/:slug";
export const PRODUCT = "san-pham/";

export const TOPIC_URL = "chu-de/:slug";
export const TOPIC = "chu-de/";

export const POST_URL = "bai-viet/:slug";
export const POST = "bai-viet/";

export const CONTACT_URL = "lien-he";
export const CONTACT = "lien-he";

export const CART_URL = "gio-hang";
export const CART = "gio-hang";
