import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Icon from "../../components/Icon";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../utils/yupGlobal";
import Input from "../../components/Input";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Contact = () => {
	const [shop, setShop] = useState([]);
	const [isSendForm, setIsSendForm] = useState(false);

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		document.title = "Liên hệ";

		getSetting();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getSetting = async () => {
		await axios
			.get(`/shop/detail`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setShop(res.data.shop);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const sendMail = async (data) => {
		setIsSendForm(true);

		await axios
			.post(`/sendMail`, {
				from: shop.shopEmail,
				to: shop.shopEmail,
				subject: "THẠCH CAO HÀO NGUYỄN CẦN THƠ",
				fullname: data.fullname,
				phonenumber: data.phonenumber,
				email: data.email || "",
				message: data.message || "",
			})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.status) {
					reset({
						fullname: "",
						email: "",
						phonenumber: "",
						message: "",
					});
					toast(
						"Đã gửi thông tin thành công, chủ shop sẽ liên hệ với bạn sau",
						{
							type: "success",
							autoClose: 3000,
						},
					);
				} else {
					toast("Có lỗi xảy ra, vui lòng thử lại sau", {
						type: "error",
						autoClose: 2000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<>
			<div className="title-layout">Liên hệ</div>
			<Container className="container-wrapper">
				<Row>
					<Col xs="6" md="3" className="text-center mb-3">
						<Icon
							icon="phone"
							className="text-color"
							style={{ height: "2rem", width: "2rem" }}
						/>
						<div className="fw-bold my-2">Số điện thoại</div>
						<div>{shop.shopPhone}</div>
					</Col>
					<Col xs="6" md="3" className="text-center mb-3">
						<Icon
							icon="location-dot"
							className="text-color"
							style={{ height: "2rem", width: "2rem" }}
						/>
						<div className="fw-bold my-2">Địa chỉ</div>
						<div>{shop.shopAddressVi}</div>
					</Col>
					<Col xs="6" md="3" className="text-center mb-3">
						<Icon
							icon="clock"
							className="text-color"
							style={{ height: "2rem", width: "2rem" }}
						/>
						<div className="fw-bold my-2">Giờ mở cửa</div>
						<div>{shop.shopAddressEn}</div>
					</Col>
					<Col xs="6" md="3" className="text-center mb-3">
						<Icon
							icon="envelope"
							className="text-color"
							style={{ height: "2rem", width: "2rem" }}
						/>
						<div className="fw-bold my-2">Email</div>
						<div>{shop.shopEmail}</div>
					</Col>
				</Row>

				<iframe
					title="thach cao hao nguyen"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.9489784787443!2d105.77662864174249!3d10.021069247210677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a088281ad923db%3A0x32409f22df7de016!2zMzEgUXVhbmcgVHJ1bmcsIEjGsG5nIFBow7osIEPDoWkgUsSDbmcsIEPhuqduIFRoxqEsIFZpZXRuYW0!5e0!3m2!1sen!2s!4v1695434989741!5m2!1sen!2s"
					width="100%"
					height="500"
					allowFullScreen=""
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
					className="py-5"
				/>

				<Form
					className="mb-5"
					onSubmit={handleSubmit((data) => sendMail(data))}
				>
					<h4 className="text-center mb-5">Liên hệ với chúng tôi</h4>

					<Input
						placeholder="Họ và tên"
						name="fullname"
						register={register("fullname")}
						errors={errors.fullname}
						className="mb-3"
					/>

					<Row>
						<Col xs={12} md={6}>
							<Input
								placeholder="Số điện thoại"
								name="phonenumber"
								register={register("phonenumber")}
								errors={errors.phonenumber}
								className="mb-3"
							/>
						</Col>
						<Col xs={12} md={6}>
							<Input
								placeholder="Email"
								name="email"
								register={register("email")}
								errors={errors.email}
								className="mb-3"
							/>
						</Col>
					</Row>
					<div className="mb-3">
						<Input
							as="textarea"
							rows={5}
							placeholder="Lời nhắn"
							name="message"
							register={register("message")}
							errors={errors.message}
							className="mb-3"
						/>
					</div>

					<div className="d-flex justify-content-center mb-3">
						<Button
							type="submit"
							size="lg"
							className="fw-bold px-5 primary-button"
							style={{
								borderRadius: 0,
							}}
							disabled={isSendForm}
						>
							Gửi đi
						</Button>
					</div>
				</Form>
			</Container>
		</>
	);
};

const Schema = yup.object().shape({
	fullname: yup.string().required("Không được bỏ trống"),
	phonenumber: yup
		.string()
		.required("Vui lòng nhập số điện thoại")
		.phone("Số điện thoại không đúng địng dạng"),
	email: yup.string().email("Email không đúng định dạng"),
});

export default Contact;
