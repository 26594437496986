import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import { POST } from "../routes/routerUrl";
import DateFormat from "./DateFormat";
import Loading from "./Loading";
import NoData from "./NoData";
import ImageURL from "./ImageURL";
import MyPagination from "./MyPagination";

const ListPost = ({
	title,
	showRow,
	posts,
	isLoading,
	itemsCount,
	itemsPerPage,
	currentPage,
	setCurrentPage,
	isPagiantion,
}) => {
	return (
		<Container className="my-5">
			<h3 className="text-center fw-bold mb-3">{title}</h3>

			<Row>
				{isLoading ? (
					<Loading />
				) : posts?.length > 0 ? (
					posts?.map((post) => {
						return (
							<Col
								xs={12}
								md={12 / showRow}
								key={post.id}
								className="mt-3"
							>
								<Card
									style={{
										height: "100%",
										border: "none",
									}}
								>
									<ImageURL
										style={{
											aspectRatio: "900/600",
											backgroundSize: "cover",
										}}
										imageURL={post.imgURL}
									/>
									<Card.Body className="text-start">
										<div
											style={{ color: "#b2b2b2" }}
											className="d-flex"
										>
											<Icon icon="calendar" />
											<DateFormat
												date={post.updatedAt}
												className={`ms-2`}
											/>
										</div>
										<Link
											to={`/${POST}${post.postSlug}`}
											style={{
												textDecoration: "none",
												color: "black",
												fontSize: "1rem",
											}}
											className="my-3"
										>
											<Card.Title className="text-overflow-3-line">
												{post.postTitleVi}
											</Card.Title>
										</Link>
										<Card.Text className="text-overflow-4-line">
											{post.postDescVi}
										</Card.Text>
									</Card.Body>
								</Card>
							</Col>
						);
					})
				) : (
					<NoData />
				)}
			</Row>

			{isPagiantion && (
				<MyPagination
					itemsCount={itemsCount}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					alwaysShown={false}
				/>
			)}
		</Container>
	);
};

export default ListPost;
