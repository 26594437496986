import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ListProduct from "../../components/ListProduct";
import ListPost from "../../components/ListPost";

import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { CATEGORY } from "../../routes/routerUrl";
import Loading from "../../components/Loading";
import ImageURL from "../../components/ImageURL";

const responsive = {
	large: {
		breakpoint: { max: 3000, min: 1400 },
		items: 4,
		slidesToSlide: 1,
	},
	desktop: {
		breakpoint: { max: 1400, min: 992 },
		items: 3,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 992, min: 768 },
		items: 2,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 768, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

const Home = () => {
	const [categories, setCategories] = useState([]);
	const [isCateLoading, setIsCateLoading] = useState([]);

	const [posts, setPosts] = useState([]);
	const [isPostLoading, setIsPostLoading] = useState([]);

	const [products, setProducts] = useState([]);
	const [isProductLoading, setIsProductLoading] = useState([]);

	const [topBanners, setTopBanners] = useState([]);
	const [bottomBanners, setBottomBanners] = useState([]);

	const [isBannerLoading, setIsBannerLoading] = useState([]);

	useEffect(() => {
		document.title = "Hào Nguyễn";
		getAllCategories();
		getAllPosts();
		getAllProducts();
		getAllBanner();
	}, []);

	// API
	const getAllCategories = async () => {
		setIsCateLoading(true);
		await axios
			.get(`/category/list`, {
				params: {
					isType: "cPro",
				},
			})
			.then((res) => {
				setIsCateLoading(false);
				if (res.data.errCode === 0) {
					setCategories(res.data.categories.filter((category) => category.parentId !== null));
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllPosts = async () => {
		setIsPostLoading(true);
		await axios
			.get(`/post/list`, {
				params: {
					limit: 6,
				},
			})
			.then((res) => {
				setIsPostLoading(false);

				if (res.data.errCode === 0) {
					setPosts(res.data.posts);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllProducts = async (slug) => {
		setIsProductLoading(true);
		await axios
			.get(`/product/list`, {
				params: {
					cateSlug: slug === "tat-ca" ? "" : slug,
					limit: 8,
				},
			})
			.then((res) => {
				setIsProductLoading(false);

				if (res.data.errCode === 0) {
					setProducts(
						res.data.products.map((product) => {
							return {
								...product,
								imgPaths: product.imgPaths ? product.imgPaths.split(",") : [],
							};
						}),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllBanner = async () => {
		setIsBannerLoading(true);
		await axios
			.get(`/image/list`, {
				params: {
					isType: "iHome",
				},
			})
			.then((res) => {
				setIsBannerLoading(false);

				if (res.data.errCode === 0) {
					setTopBanners(res.data.images.filter((image) => image.locaCode === "iBanner"));
					setBottomBanners(res.data.images.filter((image) => image.locaCode === "iFooter"));
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<Container className="container-wrapper">
			<Row className="my-4">
				<Col xs="0" md="3"></Col>
				<Col xs="12" md="6">
					{isBannerLoading ? <Loading /> : <ImageURL style={{ aspectRatio: "2/1" }} imageURL={topBanners[0]?.imgURL} />}
				</Col>
				<Col xs="0" md="3"></Col>
			</Row>

			{isCateLoading ? (
				<Loading />
			) : (
				<Carousel responsive={responsive} className="my-5" autoPlay infinite={true} removeArrowOnDeviceType={["mobile"]}>
					{categories?.map((category) => {
						return (
							<Link
								to={`${CATEGORY}${category.cateSlug}`}
								key={category.id}
								style={{
									color: "black",
									textDecoration: "none",
									fontSize: "1.5rem",
									fontWeight: "bold",
								}}
							>
								<div className="mx-3">
									<div
										style={{
											width: "100%",
											aspectRatio: "900/600",
											backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${category.imgURL})`,
											backgroundRepeat: "no-repeat",
											backgroundSize: "cover",
											backgroundPosition: "center",
										}}
									/>
									<div className="py-2 text-center text-overflow-2-line">{category.cateNameVi}</div>
								</div>
							</Link>
						);
					})}
				</Carousel>
			)}

			<ListProduct title="Sản phẩm nổi bật" showRow={4} products={products} isLoading={isProductLoading} />

			<Row>
				{isBannerLoading ? (
					<Loading />
				) : (
					bottomBanners.map((banner) => {
						return (
							<Col key={banner.id} xs={12} md={6}>
								<ImageURL style={{ aspectRatio: "2/1" }} imageURL={banner.imgURL} />
							</Col>
						);
					})
				)}
			</Row>

			<ListPost title="Tin tức" showRow={3} posts={posts} isLoading={isPostLoading} />
		</Container>
	);
};

export default Home;
