import {
	Breadcrumb,
	// Card,
	Col,
	Container,
	Row,
} from "react-bootstrap";
import ListProduct from "../../../components/ListProduct";
import { Link, useLocation, useParams } from "react-router-dom";
// import Carousel from "react-multi-carousel";
// import CurrencyFormat from "react-currency-format";
import {
	CATEGORY,
	// PRODUCT
} from "../../../routes/routerUrl";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

// const responsive = {
// 	desktop: {
// 		breakpoint: { max: 3000, min: 1024 },
// 		items: 1,
// 		slidesToSlide: 1,
// 	},
// 	mobile: {
// 		breakpoint: { max: 1024, min: 0 },
// 		items: 1,
// 		slidesToSlide: 1,
// 	},
// };

const itemsPerPage = 16;

const Category = () => {
	let { slug } = useParams();
	const location = useLocation();

	const [categories, setCategories] = useState([]);
	const [products, setProducts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(1);

	const [isLoading, setIsLoading] = useState([]);

	useEffect(() => {
		document.title = "Sản phẩm";
		getAllCategories();
	}, []);

	useEffect(() => {
		getAllProducts(slug, 1);
	}, [slug]);

	useEffect(() => {
		getAllProducts(slug, currentPage);
	}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getAllCategories = async () => {
		await axios
			.get(`/category/list`, {
				params: {
					isType: "cPro",
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setCategories(
						[
							{ id: 0, cateNameVi: "Tất cả", cateSlug: "tat-ca" },
						].concat(
							res.data.categories.filter(
								(category) => category.parentId !== null,
							),
						),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllProducts = async (slug, page) => {
		setIsLoading(true);
		await axios
			.get(`/product/list`, {
				params: {
					cateSlug: slug === "tat-ca" ? "" : slug,
					page: page,
					limit: itemsPerPage,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setTotalItems(res.data.itemCount);

					setProducts(
						res.data.products.map((product) => {
							return {
								...product,
								imgPaths: product.imgPaths
									? product.imgPaths.split(",")
									: [],
							};
						}),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<>
			<div className="title-layout">Sản phẩm</div>
			<Container className="container-wrapper">
				<Breadcrumb
					style={{
						backgroundColor: "#F5F5F5",
					}}
					className="p-3 pb-1"
				>
					<Breadcrumb.Item active>Trang chủ</Breadcrumb.Item>
					<Breadcrumb.Item active>Sản phẩm</Breadcrumb.Item>
				</Breadcrumb>
				<Row className="py-5">
					<Col xs="12" md="3">
						<div
							style={{
								position: "sticky",
								top: "5.5rem",
							}}
						>
							<h6 className="fw-bold">Danh mục</h6>
							<br />

							{categories?.map((category) => {
								return (
									<Link
										key={category.id}
										className={
											location.pathname.endsWith(
												`/${category.cateSlug}`,
											)
												? "text-link text-link-hover"
												: "text-link"
										}
										to={`/${CATEGORY}${category.cateSlug}`}
										onClick={() => setCurrentPage(1)}
									>
										<p>{category.cateNameVi}</p>
									</Link>
								);
							})}
						</div>

						{/* <div className="my-5">
							<h6 className="fw-bold">Sản phẩm mới</h6>
							<br />

							<Carousel
								responsive={responsive}
								autoPlay
								infinite={true}
								removeArrowOnDeviceType={["desktop", "mobile"]}
							>
								{products?.map((product) => {
									return (
										<div
											key={product.id}
											style={{ height: "100%" }}
										>
											<Link
												to={`/${PRODUCT}${product.slug}`}
												style={{
													textDecoration: "none",
													color: "black",
												}}
												className="d-flex text-start"
											>
												<div
													style={{
														width: "6rem",
														height: "6rem",
														backgroundImage: `url(${product.image})`,
														backgroundRepeat:
															"no-repeat",
														backgroundSize:
															"contain",
													}}
												/>

												<div className="ms-2">
													<Card.Text>
														{product.name}
													</Card.Text>
													<Card.Title className="fw-bold">
														<CurrencyFormat
															value={
																product.price
															}
															thousandSeparator={
																true
															}
															allowNegative={
																false
															}
															isNumericString
															displayType="text"
															suffix="đ"
														/>
													</Card.Title>
												</div>
											</Link>

											<Link
												to={`/${PRODUCT}${product.slug}`}
												style={{
													textDecoration: "none",
													color: "black",
												}}
												className="d-flex text-start"
											>
												<div
													style={{
														width: "6rem",
														height: "6rem",
														backgroundImage: `url(${product.image})`,
														backgroundRepeat:
															"no-repeat",
														backgroundSize:
															"contain",
													}}
												/>

												<div className="ms-2">
													<Card.Text>
														{product.name}
													</Card.Text>
													<Card.Title className="fw-bold">
														<CurrencyFormat
															value={
																product.price
															}
															thousandSeparator={
																true
															}
															allowNegative={
																false
															}
															isNumericString
															displayType="text"
															suffix="đ"
														/>
													</Card.Title>
												</div>
											</Link>

											<Link
												to={`/${PRODUCT}${product.slug}`}
												style={{
													textDecoration: "none",
													color: "black",
												}}
												className="d-flex text-start"
											>
												<div
													style={{
														width: "6rem",
														height: "6rem",
														backgroundImage: `url(${product.image})`,
														backgroundRepeat:
															"no-repeat",
														backgroundSize:
															"contain",
													}}
												/>

												<div className="ms-2">
													<Card.Text>
														{product.name}
													</Card.Text>
													<Card.Title className="fw-bold">
														<CurrencyFormat
															value={
																product.price
															}
															thousandSeparator={
																true
															}
															allowNegative={
																false
															}
															isNumericString
															displayType="text"
															suffix="đ"
														/>
													</Card.Title>
												</div>
											</Link>
										</div>
									);
								})}
							</Carousel>
						</div> */}
					</Col>
					<Col xs="12" md="9">
						<ListProduct
							showRow={3}
							products={products}
							isLoading={isLoading}
							itemsCount={totalItems}
							itemsPerPage={itemsPerPage}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							isPagiantion={true}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Category;
