import React from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../components/Icon";

const navbarItems = [
	{
		id: 1,
		label: "Tổng quan",
		icon: "gauge",
		link: "",
	},
	{
		id: 2,
		label: "Danh mục sản phẩm",
		icon: "tags",
		link: "category",
	},
	{
		id: 3,
		label: "Sản phẩm",
		icon: "layer-group",
		link: "product",
	},
	{
		id: 4,
		label: "Chủ đề",
		icon: "book-open-reader",
		link: "topic",
	},
	{
		id: 5,
		label: "Tin tức",
		icon: "newspaper",
		link: "post",
	},
	{
		id: 6,
		label: "Banner",
		icon: "image",
		link: "banner",
	},
	{
		id: 7,
		label: "Cài đặt",
		icon: "gear",
		link: "setting",
	},
];

const Sidebar = () => {
	const location = useLocation();

	return (
		<nav
			className="sb-sidenav accordion sb-sidenav-light"
			id="sidenavAccordion"
		>
			<div className="sb-sidenav-menu">
				<div className="nav">
					{navbarItems?.map((item) => {
						return (
							<Link
								key={item.id}
								to={`/admin/${item.link}`}
								className={`nav-link nav-link-hover ${
									item.id === 1
										? location.pathname === "/admin" ||
										  location.pathname === "/admin/"
											? "nav-link-on-hover active"
											: ""
										: location.pathname.startsWith(
												`/admin/${item.link}`,
										  )
										? "nav-link-on-hover active"
										: ""
								}`}
							>
								<div className="sb-nav-link-icon me-2">
									<Icon icon={item.icon} />
								</div>
								{item.label}
							</Link>
						);
					})}
				</div>
			</div>
		</nav>
	);
};

export default Sidebar;
