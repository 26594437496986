const Loading = () => {
	return (
		<div className="d-flex justify-content-center my-5">
			<div
				className="spinner-border text-primary"
				style={{ width: "3rem", height: "3rem" }}
				role="status"
			>
				<span className="sr-only">Đang tải</span>
			</div>
		</div>
	);
};

export default Loading;
