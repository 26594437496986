import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Container, Row } from "react-bootstrap";

import DataTables from "../../../components/DataTables";
import Icon from "../../../components/Icon";
import BannerEditModal from "./BannerEditModal";
import Loading from "../../../components/Loading";
import DateFormat from "../../../components/DateFormat";
import ImageURL from "../../../components/ImageURL";

const Banner = () => {
	const [banners, setBanners] = useState([]);
	const [showEditModal, setShowEditModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [selectedBanner, setSelectedBanner] = useState({
		id: 0,
		name: "",
	});

	useEffect(() => {
		getAllBanner();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getAllBanner = async () => {
		setIsLoading(true);
		await axios
			.get(`/image/show`, {
				params: {
					isType: "iHome",
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setBanners(res.data.images);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	const handleCloseEditModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllBanner();
		}
	};
	const handleShowEditModal = (bannerId, name) => {
		setShowEditModal(true);
		setSelectedBanner(bannerId);
	};

	const columns = [
		{
			style: {
				display: "flex",
				justifyContent: "center",
			},
			name: "Hình ảnh",
			selector: (row) => (
				<ImageURL
					style={{
						aspectRatio: "900/600",
						height: "3.5rem",
						backgroundSize: "cover",
					}}
					imageURL={row.imgURL}
				/>
			),
		},
		{
			name: "Vị trí",
			selector: (row) => row.locaCode,
		},
		{
			name: "Cập nhật",
			selector: (row) => <DateFormat date={row.updatedAt} />,
		},
		{
			name: "Phương thức",
			center: true,
			cell: (row) => (
				<>
					<Button
						size="sm"
						variant="warning me-2"
						type="button"
						onClick={() => handleShowEditModal(row.id)}
					>
						<Icon icon="pencil" />
					</Button>
				</>
			),
		},
	];

	return (
		<Container fluid>
			<div
				style={{
					backgroundColor: "rgba(221,237,224,255)",
					fontSize: "1.5rem",
				}}
				className="text-center w-100 mb-3 py-3 mt-3"
			>
				Banner
			</div>

			<Row>
				{isLoading ? (
					<Loading />
				) : (
					<DataTables data={banners} columns={columns} />
				)}
			</Row>

			{showEditModal ? (
				<BannerEditModal
					handleCloseModal={handleCloseEditModal}
					showModal={showEditModal}
					selectedBanner={selectedBanner}
				/>
			) : (
				""
			)}
		</Container>
	);
};

export default Banner;
