import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PRODUCT } from "../routes/routerUrl";
import Loading from "./Loading";
import NoData from "./NoData";
import ImageURL from "./ImageURL";
import MyPagination from "./MyPagination";

const ListProduct = ({
	title,
	showRow,
	products,
	isLoading,
	itemsCount,
	itemsPerPage,
	currentPage,
	setCurrentPage,
	isPagiantion,
}) => {
	return (
		<Container className="my-5">
			<h3 className="text-center fw-bold mb-3">{title}</h3>

			<Row>
				{isLoading ? (
					<Loading />
				) : products?.length > 0 ? (
					products?.map((product) => {
						return (
							<Col
								xs={6}
								md={12 / showRow}
								key={product.id}
								className="mt-3"
							>
								<Card
									style={{ height: "100%", border: "none" }}
								>
									<ImageURL
										style={{
											aspectRatio: "900/600",
											backgroundSize: "cover",
										}}
										imageURL={product.imgPaths[0]}
									/>
									<Card.Body className="text-center">
										<Link
											to={`/${PRODUCT}${product.proSlug}`}
											style={{
												textDecoration: "none",
												color: "black",
											}}
										>
											<Card.Text
												style={{ fontSize: "1.2rem" }}
												className="my-2 text-overflow-2-line"
											>
												{product.proNameVi}
											</Card.Text>
										</Link>
									</Card.Body>
								</Card>
							</Col>
						);
					})
				) : (
					<NoData />
				)}
			</Row>

			{isPagiantion && (
				<MyPagination
					itemsCount={itemsCount}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					alwaysShown={false}
				/>
			)}
		</Container>
	);
};

export default ListProduct;
