import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Col, Container, Dropdown, Form, Row } from "react-bootstrap";

import { Link, Outlet, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Icon from "../../components/Icon";
import { CATEGORY } from "../../routes/routerUrl";

import "./MasterLayout.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import ZaloLogo from "../../assets/image/zalo-logo.png";

function MasterLayout() {
	const navigate = useNavigate();

	const [keyword, setKeyword] = useState("");

	const [showCateDropdown, setShowCateDropdown] = useState(false);
	const [showSideBar, setShowSideBar] = useState(false);

	const [shop, setShop] = useState([]);
	const [topics, setTopics] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		getSetting();
		getAllTopics();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getSetting = async () => {
		await axios
			.get(`/shop/detail`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setShop(res.data.shop);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllTopics = async () => {
		await axios
			.get(`/category/list`, {
				params: {
					isType: "cPost",
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setTopics(
						[{ id: 0, cateNameVi: "Tất cả", cateSlug: "tat-ca" }].concat(res.data.categories.filter((category) => category.parentId !== null)),
					);
					getAllCategories();
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllCategories = async () => {
		await axios
			.get(`/category/list`, {
				params: {
					isType: "cPro",
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setCategories(
						[{ id: 0, cateNameVi: "Tất cả", cateSlug: "tat-ca" }].concat(res.data.categories.filter((category) => category.parentId !== null)),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<div>
			<Navbar setShowSideBar={setShowSideBar} showSideBar={showSideBar} shop={shop} topics={topics} categories={categories} />

			<Sidebar setShowSideBar={setShowSideBar} showSideBar={showSideBar} shop={shop} topics={topics} categories={categories} />

			<div className="padding-nav" onClick={() => setShowSideBar(false)}>
				<div className={`${showSideBar ? "curtain active" : "curtain"}`} />

				<Container className="container-wrapper">
					<Row className="align-items-center pt-3">
						<Col xs="12" md="3" className="mb-3 mb-md-0">
							<Dropdown show={showCateDropdown}>
								<Dropdown.Toggle
									size="lg"
									style={{
										borderRadius: 0,
										width: "100%",
										fontSize: "1.1rem",
									}}
									className="fw-bold text-wrap search-button"
									onClick={() => setShowCateDropdown(!showCateDropdown)}
								>
									<Icon icon="bars" /> Tất cả danh mục <Icon icon="chevron-down" />
								</Dropdown.Toggle>

								<Dropdown.Menu style={{ borderRadius: 0, width: "100%" }}>
									{categories?.map((category) => {
										return (
											<Dropdown.Item key={category.id} as={Link} to={`/${CATEGORY}${category.cateSlug}`} className="sub-navbar-text">
												{category.cateNameVi}
											</Dropdown.Item>
										);
									})}
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						<Col xs="12" md="6">
							<Form
								onSubmit={(e) => {
									e.preventDefault();
									navigate(`/tim-kiem/${keyword}`);
								}}
								className="d-flex"
							>
								<Form.Control
									size="lg"
									style={{
										borderRadius: "0px",
										fontSize: "1.1rem",
									}}
									onChange={(e) => setKeyword(e.target.value)}
									placeholder="Bạn tìm gì?"
								/>
								<Button style={{ borderRadius: "0px" }} size="lg" className="search-button primary-button" type="submit">
									<Icon icon="magnifying-glass" />
								</Button>
							</Form>
						</Col>
						<Col md="3" className="d-none d-md-block">
							<Row className="justify-content-center align-items-center">
								<Col xs={3}>
									<div
										className="circle d-flex justify-content-center align-items-center p-0 m-0"
										style={{
											backgroundColor: "#f5f5f5",
										}}
									>
										<Icon icon="phone" />
									</div>
								</Col>
								<Col xs={9}>
									<div className="fw-bold">{shop.phonenumber}</div>
									<div>Hỗ trợ 24/7</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>

				<Outlet />

				<div
					style={{
						display: "inline-block",
						position: "fixed",
						bottom: "50px",
						right: "2rem",
						opacity: 0.8,
						zIndex: 9999,
					}}
				>
					{shop.shopDescVi ? (
						<>
							<Link
								to={`https://www.facebook.com/messages/t/${shop.shopDescVi.substring(shop.shopDescVi.indexOf("=") + 1)}`}
								target="_blank"
								className="d-block"
								title="Nhắn tin FaceBook"
							>
								<FontAwesomeIcon
									icon={faFacebookMessenger}
									size="2xl"
									style={{
										height: "40px",
										width: "40px",
										color: "blue !important",
										opacity: 0.9,
									}}
									shake
								/>

								<span></span>
							</Link>
							<br />{" "}
						</>
					) : (
						""
					)}

					<Link to={`https://zalo.me/${shop.shopDescEn}`} target="_blank" className="d-block" title="Nhắn tin FaceBook">
						<img src={ZaloLogo} alt="PhanBonSach" style={{ width: "40px", height: "40px" }} />

						<span></span>
					</Link>
					<br />
					<div title="Về đầu trang" id="top-up" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
						<FontAwesomeIcon
							icon={faArrowAltCircleUp}
							size="2xl"
							style={{
								textAlign: "center",
								height: "40px",
								width: "40px",
								color: "var(--primary-color)",
								cursor: "pointer",
							}}
						/>
					</div>
				</div>

				<Footer shop={shop} />
			</div>
		</div>
	);
}

export default MasterLayout;
